<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }" @click="mqShallShowLeftSidebar = false" />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input v-model="searchQuery" :placeholder="$t('message.search')" @input="searchTask" />
            <b-input-group-append is-text v-if="searchQuery">
              <feather-icon icon="XIcon" class="text-muted cursor-pointer" @click="resetSearch" />
            </b-input-group-append>
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <!-- <div class="dropdown">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="resetSortAndNavigate">
              Reset Sort
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
              Sort Z-A
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
              Sort Due Date
            </b-dropdown-item>
          </b-dropdown>
        </div> -->
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="todo-task-list-wrapper list-group scroll-area">
        <draggable v-show="!isLoading" v-model="tasks" handle=".draggable-task-handle" tag="ul"
          class="todo-task-list media-list">
          <li v-for="(task, index) in tasks" :key="task._id" class="todo-item" :class="{ 'completed': task.completed }"
            @click="handleTaskClick(task)">
            <feather-icon icon="MoreVerticalIcon" class="draggable-task-handle d-inline" />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    v-if="!commitmentFunctionality || (task.completed && !task.evidenceKey)"
                    :checked="task.completed"
                    @click.native.stop
                    @change="updateTaskIsCompleted(task)"
                  />
                  <b-form-checkbox
                    v-else
                    :checked="task.completed"
                    @click.native.stop
                    @click.native.prevent
                    @change="updateTaskIsCompleted(task)"
                    v-b-modal="'uploadEvidence' + index"
                  />
                  <span class="todo-title">{{ task.note }}</span>
                  <img v-if="assistanceIAFunctionality && task.assistance" src="@/assets/images/avatars/iconoChatbot.png" alt="chatbot" class="chatbot">
                  <b-modal
                    v-if="!task.completed || task.evidenceKey"
                    :id="'uploadEvidence' + index"
                    centered
                    no-close-on-backdrop
                    :modal-class="task.completed ? 'modal-danger' : 'modal-success'"
                    :ok-variant="task.completed ? 'danger' : 'success'"
                    cancel-variant="outline-secondary"
                    :title="$t('message.confirm_action')"
                    ok-title="OK"
                    :cancel-title="$t('message.cancel')"
                    :ok-disabled="!docEvidence && !imgEvidence && !task.completed"
                    @ok="handleModalOk(task)"
                    @hide="handleModalHide"
                  > {{ !task.completed ? $t('label.fileRequiredMessage') : $t('label.evidenceDeletedMessage') }}
                
                    <!-- Buttons to upload evidence -->
                    <div v-if="!task.completed" class="my-1">
                      <b-button   
                        @click="uploadImageOrFile('img')"
                        variant="outline-secondary"
                        class="mt-1 mr-1"
                        :disabled="disabledImg"
                        > {{ $t('label.img') }}
                      </b-button>
                      <b-button
                        @click="uploadImageOrFile('doc')"
                        variant="outline-secondary"
                        class="mt-1"
                        :disabled="disabledDoc"
                        > {{ $t('label.document') }}
                      </b-button>
                    </div>
                    <small v-if="!docEvidence && !imgEvidence && !task.completed" class="text-danger">
                      {{ $t('label.fileRequired') }}
                    </small>
                    <div v-if="docEvidence || imgEvidence">
                      <feather-icon
                          icon="PaperclipIcon"
                          size="15"
                          style="opacity: 0.5;"
                      />
                      <small class="text-secondary">
                        {{ docEvidence ? docEvidence.name : imgEvidence.name  }} 
                      </small>
                    </div>             
                  </b-modal>
                </div>
                <feather-icon
                  v-if="task.evidenceKey && task.evidenceKey.key"
                  icon="PaperclipIcon"
                  size="13"
                  style="opacity: 0.4;"
                />
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge v-for="tag in task.tags" :key="tag" pill :variant="`light-${resolveTagVariant(tag)}`"
                    class="text-capitalize">
                    {{ $t(`domain.${tag}`) }}
                  </b-badge>
                </div>
                <small :class="`text-nowrap mr-1 ${setDateColor(task.dueDate, task.completed)}`">
                  {{ formatDate(task.dueDate, { month: 'short', day: 'numeric' }) }}
                </small>
                <b-avatar v-if="task.assignee" size="32" :variant="`light-${resolveAvatarVariant(task.tags)}`"
                  :text="avatarText(task.assignee.name)" />
                <!-- :src="task.assignee.avatar"    optional attribute inside b-avatar-->
                <b-avatar v-else size="32" variant="light-secondary">
                  <feather-icon icon="UserIcon" size="16" />
                </b-avatar>
              </div>
            </div>

          </li>
          <image-compressor
            ref="compressor"
            style="display: none"
            :done="getImgEvidence"
          />
          <input
            type="file"
            ref="docSelector"
            style="display: none"
            accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            @change="getDocEvidence"
          />
        </draggable>
        <div class="no-results" :class="{ 'show': !isLoading && !tasks.length }">
          <h5>{{ commitmentFunctionality ? $t('message.no_commitments_found') : $t('message.no_improvements_found') }}
          </h5>
        </div>
        <loading v-if="isLoading" :active="true" :is-full-page="false" :color="colors.primary" />
        <!-- <div
          class="no-results"
          :class="{'show': isLoading}"
        >
          <h5>{{ $t('message.loading_improvements') }}</h5>
        </div> -->
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar v-model="isTaskHandlerSidebarActive" :task="task" :clear-task-data="clearTaskData"
      :domains="domains" :optionsMetadata="optionsMetadata" handlerId="tab" @remove-task="removeTask" @add-task="addTaskAndRefetch"
      @update-task="updateTask" />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar :dataExcel="dataExcel" :task-tags="domains" :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{ 'show': mqShallShowLeftSidebar }" @close-left-sidebar="mqShallShowLeftSidebar = false" />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import { BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BDropdown, BDropdownItem, BFormCheckbox, BBadge, BAvatar } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import useCommonTodo from './useCommonTodo'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import awsConnection from '@/views/habit/aws'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import useCommon from "@/views/organization/useCommon"
import ImageCompressor from '@/views/habit/ImageCompressor.vue'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    Loading,
    ImageCompressor,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'
    
    const userData = store.state?.userStore?.userData
    const userId = userData.worker_id?.$oid || null
    const clientId = userData.role !== "admin" ? userData.client.$oid : null
    const isLoading = ref(true)
    const categoryFilter = ref("all")
    const tagFilter = ref("all")
    const now = new Date()
    const nowPlusOneWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)

    const commitmentFunctionality = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
      : false
    const assistanceIAFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').assistanceIA_functionality

    const { resolveTagVariant, resolveAvatarVariant, sendEmailImprovements, addTask } = useCommonTodo()
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { singleUpload } = awsConnection()
    const { getItemsWithAggregate, updateItem, ObjectId } = realmConnection()

    const { getDomains, getDatesFromRange } = useCommonDashboards()
    const domains = computed(() => getDomains(clientId))

    const { getMetadataForDropDown, metadataNotMapped, handleError, formatIsoDate } = useCommon()

    const docSelector = ref(null)
    const compressor = ref(null)
    const okClicked = ref(false)
    const docEvidence = ref(null)
    const imgEvidence = ref(null)
    const disabledImg = ref(false)
    const disabledDoc = ref(false)
    const deleteEvidence = ref(false)
    const collection = 'improvement'

    const handleModalOk = async (taskData) => {
      if (taskData.completed) deleteEvidence.value = true
      // Save evidence of completed commit in AWS and DB
      const evidenceFile = imgEvidence.value ? imgEvidence.value : docEvidence.value
      const type = imgEvidence.value ? 'img' : 'doc'
      if(evidenceFile) {
        try {
          const key = await saveEvidenceAWS(evidenceFile)
          taskData.evidenceKey = { key, type }
        } catch (err) {
          console.error(err)
        }
      }
      // Delete evidence of commitment in DB
      if (deleteEvidence.value) {
        taskData.evidenceKey = {}
      }
      taskData.completed = !taskData.completed
      okClicked.value = true
      const action = taskData.completed ? "completeFromList" : "incompleteFromList"
      updateTask(taskData, -1, action)
    }

    const uploadImageOrFile = (type) => {
      type === 'img'?  compressor.value.$el.click() : docSelector.value.click()
    }

    const getDocEvidence = (e) => {
      docEvidence.value = e.target.files[0]
      disabledImg.value = true
    }

    const handleModalHide = () => {
      if(!okClicked.value)
      docEvidence.value = null
      imgEvidence.value = null
      disabledImg.value = false
      disabledDoc.value = false
      okClicked.value = false
      deleteEvidence.value = false
    }

    const getImgEvidence = (obj) => {
      imgEvidence.value = obj.compressed
      disabledDoc.value = true
    }

    const saveEvidenceAWS = (file) => {
        const destinationFolder = `${userData.client.$oid}/evidenceCommitments`;
        return new Promise((resolve, reject) => {
          singleUpload(file, destinationFolder)
            .then((key) => resolve(key))
            .catch((err) => reject(err))
      })
    }

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // Reset store states for filters
    store.commit('app-todo/SET_SELECTED_LOCATIONS', [])
    store.commit('app-todo/SET_SELECTED_WORKERS', [])
    store.commit('app-todo/SET_SELECTED_DUE_DATES', "")
    store.commit('app-todo/SET_SELECTED_INSTANCES', [])
    store.commit('app-todo/SET_SELECTED_INSTANCE_LEADERS', [])
    store.commit('app-todo/SET_SELECTED_ZONES', [])

    // Fetch tasks and get metadata on mounted
    onMounted(async () => {
      fetchTasks()
      getMetadataForDropDown({ category: "improvement", option: "notMapped" })

      // Open TodoTaskHandlerSidebar if there is an improvement id in the route query   
      if (improvementId) {
        try {
          const query = { _id: ObjectId(improvementId) }

          const pipeline = [
            { $match: query },
            {
              $lookup: {
                from: 'worker',
                localField: 'assignee',
                foreignField: '_id',
                pipeline: [
                  { $project: { name: 1, email: 1, locations: 1 } },
                  {
                    $lookup: {
                      from: 'location',
                      localField: 'locations',
                      foreignField: '_id',
                      pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                      as: 'locations',
                    },
                  },
                  { $addFields: { _id: { $toString: "$_id" } } }
                ],
                as: 'assignee'
              }
            },
            { $addFields: { _id: { $toString: "$_id" }, origin_id: { $toString: "$origin_id" }, assistance: { $toString: "$assistance" }, assignee: { $arrayElemAt: ["$assignee", 0] }, subscribers: { $map: { input: "$subscribers", as: "id", in: { $toString: "$$id" } } } } },
          ]
        
          const items = await getItemsWithAggregate({ collection, pipeline })

          task.value = items[0]
          isTaskHandlerSidebarActive.value = true
        } catch (error) {
          console.log(error)
        }
      }
    })

    // UnRegister on leave (can't unregister because of 'Add improvement' button in navbar)
    // onUnmounted(() => {
    //   if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    // })

    const { route, router } = useRouter()
    // const routeSortBy = computed(() => route.value.query.sort)
    // const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    const improvementId = route.value.query.originId
    
    watch(routeParams, val => {
      isLoading.value = true
      categoryFilter.value = val.filter ? val.filter : "all"
      tagFilter.value = val.tag ? val.tag : "all"
      fetchTasks()
    })

    const locationFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedLocations)
    const workerFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedWorkers)
    const dueDateFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedDueDates)
    const instanceFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedInstances)
    const instanceLeaderFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedInstanceLeaders)
    const zoneFilter = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].selectedZones)

    watch([locationFilter, workerFilter, dueDateFilter, instanceFilter, instanceLeaderFilter, zoneFilter], () => {
      isLoading.value = true
      fetchTasks()
    })

    const tasks = ref([])
    const dataExcel = ref([])
    // const sortOptions = [
    //   'latest',
    //   'title-asc',
    //   'title-desc',
    //   'assignee',
    //   'due-date',
    // ]
    // const sortBy = ref(routeSortBy.value)
    // watch(routeSortBy, val => {
    //   if (sortOptions.includes(val)) sortBy.value = val
    //   else sortBy.value = val
    // })
    // const resetSortAndNavigate = () => {
    //   const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

    //   delete currentRouteQuery.sort

    //   router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
    // }

    let blankTask = {
      client_id: clientId,
      note: '',
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      description: '',
      assignee: null,
      tags: [],
      completed: false,
      deleted: false,
      important: false,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const optionsMetadata = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].optionsMetadata)

    watch(metadataNotMapped, val => {
      if (val && val.length) {
        val.forEach(e => {
          // Set default creation date
          if (e.name === "creation_date") {
            e.answer = `${now.getFullYear()}-${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`
          }
          // Set default instance leader
          if (e.name === "instance_leader") e.answer = userData.username
        })
        blankTask.metadata = val
        if (!task.value._id) task.value = { ...task.value, metadata: val }
      }
    })

    const fetchTasks = async (searchValue = '') => {
      const dueDates = getDatesFromRange(dueDateFilter.value)

      try {
        const initialQuery = {
          client_id: ObjectId(clientId),
          deleted: { $ne: true },
        }

        // Category
        if (categoryFilter.value !== "all") {
          if (categoryFilter.value === "pending" || categoryFilter.value === "overdue") {
            initialQuery.completed = { $ne: true }
            if (categoryFilter.value === "pending") {
              initialQuery.$or = [{ dueDate: { $gte: now } }, { dueDate: null }]
            } else {
              initialQuery.dueDate = { $lt: now }
            }
          } else {
            initialQuery[categoryFilter.value] = true
          }
        }

        // Tag
        if (tagFilter.value !== "all") initialQuery.tags = tagFilter.value

        // Assignee
        if (workerFilter.value.length > 0) {
          initialQuery.assignee = { $in: workerFilter.value.map(e => ObjectId(e)) }
        }

        // Due date
        if (dueDates[0]) {
          initialQuery.$and = [{ dueDate: { $gte: dueDates[0], $lt: dueDates[1] } }]
        }

        // Instance
        if (instanceFilter.value.length) {
          if (initialQuery.$and) initialQuery.$and.push({ metadata: { $elemMatch: { name: "instance", answer: { $in: instanceFilter.value } } } })
          else initialQuery.$and = [{ metadata: { $elemMatch: { name: "instance", answer: { $in: instanceFilter.value } } } }]
        }

        // Instance leader
        if (instanceLeaderFilter.value.length) {
          if (initialQuery.$and) initialQuery.$and.push({ metadata: { $elemMatch: { name: "instance_leader", answer: { $in: instanceLeaderFilter.value } } } })
          else initialQuery.$and = [{ metadata: { $elemMatch: { name: "instance_leader", answer: { $in: instanceLeaderFilter.value } } } }]
        }

        // Zone
        if (zoneFilter.value.length) {
          if (initialQuery.$and) initialQuery.$and.push({ metadata: { $elemMatch: { name: "zone", answer: { $in: zoneFilter.value } } } })
          else initialQuery.$and = [{ metadata: { $elemMatch: { name: "zone", answer: { $in: zoneFilter.value } } } }]
        }

        const finalQuery = {}

        // Location
        if (locationFilter.value.length) {
          finalQuery['assignee.locations._id'] = { $in: locationFilter.value }
        }

        // User logged as supervisor
        if (userData.role === 'supervisor') {
          finalQuery.$or = [
            { 'assignee.supervisors': ObjectId(userId) },
            { 'assignee._id': userId },
          ]
        }

        const pipeline = [
          { $match: initialQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'assignee',
              foreignField: '_id',
              pipeline: [
                { $project: { name: 1, email: 1, locations: 1, supervisors: 1 } },
                {
                  $lookup: {
                    from: 'location',
                    localField: 'locations',
                    foreignField: '_id',
                    pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'locations',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" } } }
              ],
              as: 'assignee'
            }
          },
          { $match: finalQuery },
          { $addFields: { _id: { $toString: "$_id" }, origin_id: { $toString: "$origin_id" }, assistance: { $toString: "$assistance" }, assignee: { $arrayElemAt: ["$assignee", 0] }, subscribers: { $map: { input: "$subscribers", as: "id", in: { $toString: "$$id" } } }, completedSort: { $cond: { if: { $eq: ["$completed", true] }, then: true, else: false } } } },
          { $sort: { completedSort: 1, dueDate: 1 } }
        ]

        const items = await getItemsWithAggregate({ collection, pipeline })

        if (searchValue && searchValue !== searchQuery.value) return     // To avoid early updates of the search when the user is still typing

        const taskFiltered = searchQuery.value ? items.filter(e => e.note?.toLowerCase().includes(searchQuery.value.toLowerCase())) : items

        tasks.value = taskFiltered
      
        // Calculate and update statistic card values in store
        let completedTasks = 0
        let pendingTasks = 0
        let overdueTasks = 0
        let dateNow = new Date()

        taskFiltered.forEach(e => {
          const obj = {
            note: e.note,
            assignee: e.assignee?.name,
            dueDate: e.dueDate ? formatIsoDate(e.dueDate) : '', 
            tags: e.tags?.map(t => computed(() => i18n.t(`domain.${t}`)).value)
          }
          if (e.completed) {
            completedTasks++
            obj.state = i18n.t('completed')
          }
          else if (e.dueDate && (e.dueDate < dateNow)) {
            overdueTasks++
            obj.state = i18n.t('overdue')
          } else {
            obj.state = i18n.t('pending')
            pendingTasks++
          }
          dataExcel.value.push(obj)
        })

        const totalTasks = completedTasks + pendingTasks + overdueTasks
        const statisticCardsData = {
          completed: completedTasks,
          pending: pendingTasks,
          overdue: overdueTasks,
          total: totalTasks
        }
        store.commit('app-todo/SET_STATISTIC_CARDS_DATA', statisticCardsData)
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: commitmentFunctionality ? i18n.t('message.err_commitment_list') : i18n.t('message.err_improvement_list') })
      } finally {
        if (!searchValue || searchValue === searchQuery.value) isLoading.value = false    // Don't remove the loader if the user is still typing a search
      }
    }

    const addTaskAndRefetch = async (val) => {
      try {
        await addTask(val)
        fetchTasks()
      } catch (error) {
        console.log(error)
      }
    }

    const updateTask = async (taskData, index, action) => {
      try {
        const query = { _id: ObjectId(taskData._id) }
        const setPayload = {
          note: taskData.note || '',
          assignee: ObjectId(taskData.assignee._id),
          subscribers: taskData.subscribers?.map(e => ObjectId(e)) || [],
          tags: taskData.tags || [],
          metadata: taskData.metadata || [],
          description: taskData.description || '',
          completed: taskData.completed || false,
          deleted: taskData.deleted || false,
          important: taskData.important || false,
          evidenceKey: taskData.evidenceKey || {},
        }
        const unsetPayload = {}

        if (taskData.dueDate) {
          setPayload.dueDate = taskData.dueDate instanceof Date ? taskData.dueDate : new Date(`${taskData.dueDate.slice(0, 10)} 12:00:00`)
        } else {
          unsetPayload.dueDate = ''
        }

        const realmAction = { $set: setPayload, $unset: unsetPayload }

        await updateItem({ collection, query, action: realmAction })

        if (!action.includes("FromList")) {
          if (action === "delete") showSuccessMessage(commitmentFunctionality ? i18n.t('message.commitment_deleted') : i18n.t('message.improvement_deleted'))
          else showSuccessMessage(commitmentFunctionality ? i18n.t('message.commitment_updated') : i18n.t('message.improvement_updated'))
        }

        // Refetch tasks to get updated list
        fetchTasks()

        // Send email to assignee and subscribers with the details of the improvement opportunity updated
        const updatedAction = action === "delete"
          ? "delete"
          : action.startsWith("complete")
            ? "complete"
            : "update"
        
        sendEmailImprovements([taskData], updatedAction)
      } catch (error) {
        console.log(error)
        showErrorMessage(commitmentFunctionality ? i18n.t('message.commitment_update_error') : i18n.t('message.improvement_update_error'))
      }
    }

    const removeTask = (taskData) => {
      if (!taskData.deleted) {
        taskData.deleted = true
        updateTask(taskData, -1, "delete")
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    // const taskTags = [
    //   { title: 'Team', color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'team' } } },
    //   { title: 'Low', color: 'success', route: { name: 'apps-todo-tag', params: { tag: 'low' } } },
    //   { title: 'Medium', color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'medium' } } },
    //   { title: 'High', color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'high' } } },
    //   { title: 'Update', color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'update' } } },
    // ]

    // Search Query
    const searchQuery = ref("")

    const searchTask = searchValue => {
      isLoading.value = true
      fetchTasks(searchValue)
    }

    const resetSearch = () => {
      searchQuery.value = ''
      searchTask()
    }

    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      if(!commitmentFunctionality || (taskData.completed && !taskData.evidenceKey)) {
        taskData.completed = !taskData.completed
        const action = taskData.completed ? "completeFromList" : "incompleteFromList"
        updateTask(taskData, -1, action)
      }
    }

    const setDateColor = (taskDate, completed) => {
      if (completed) return "text-success"
      const date = new Date(taskDate)
      if (date < now) return "text-danger"
      if (date > nowPlusOneWeek) return "text-muted"
      return "text-warning"
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      task,
      tasks,
      removeTask,
      addTaskAndRefetch,
      updateTask,
      clearTaskData,
      // taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      searchTask,
      resetSearch,
      // resetSortAndNavigate,
      isLoading,
      domains,
      setDateColor,
      optionsMetadata,
      commitmentFunctionality,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,

      // Evidence of task Local completed
      docSelector,
      okClicked,
      docEvidence,
      imgEvidence,
      disabledImg,
      disabledDoc,
      deleteEvidence,
      handleModalOk,
      uploadImageOrFile,
      getDocEvidence,
      handleModalHide,
      getImgEvidence,
      compressor,

      colors,
      dataExcel,
      assistanceIAFunctionality
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>

<style lang="scss" scoped>
.chatbot{
  width: 2.7rem;
  margin-right: 0.5rem;
  height: auto;
  padding-left: 0.5rem;
  opacity: 0.5;
}
</style>
