<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
            >
              {{ commitmentFunctionality ? $t('add_commitment') : $t('add_improvement') }}
            </b-button>

             <!-- Excel -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              block
              
            >
              <json-excel :data="dataExcel" :fields="excelFields">
                {{ $t('download_xlsx') }}
              </json-excel>
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="item in taskFilters"
                :key="item.title + $route.path"
                :to="item.route"
                :active="isDynamicRouteActive(item.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="item.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ item.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Tags
              </h6>
              <feather-icon icon="PlusIcon" />
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.text + $route.path"
                :to="tag.route"
                :active="isDynamicRouteActive(tag.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <span>{{ tag.text }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import { computed } from "@vue/composition-api/dist/vue-composition-api"
import JsonExcel from "vue-json-excel"

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    JsonExcel
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
    dataExcel: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const taskFilters = computed(() => {
      return [
        { title: i18n.t('all'), icon: 'ListIcon', route: { name: 'apps-todo', params: { filter: 'all' } } },
        { title: i18n.t('important'), icon: 'StarIcon', route: { name: 'apps-todo-filter', params: { filter: 'important' } } },
        { title: i18n.t('completed_all_f'), icon: 'CheckIcon', route: { name: 'apps-todo-filter', params: { filter: 'completed' } } },
        { title: i18n.t('message.in_process'), icon: 'WatchIcon', route: { name: 'apps-todo-filter', params: { filter: 'pending' } } },
        { title: i18n.t('delayed_all_f'), icon: 'XIcon', route: { name: 'apps-todo-filter', params: { filter: 'overdue' } } },
        { title: i18n.t('deleted_all_f'), icon: 'TrashIcon', route: { name: 'apps-todo-filter', params: { filter: 'deleted' } } },
      ]
    })

    const commitmentFunctionality = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
      : false

    const excelFields = {
        [i18n.t('message.tableHeader.Name')]: "note",
        [i18n.t('message.tableHeader.assignee')]: "assignee",
        [i18n.t('message.tableHeader.state')]: "state",
        [i18n.t('message.tableHeader.dueDate')]: "dueDate",
        [i18n.t('message.tableHeader.tags')]: "tags",
    }

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
      commitmentFunctionality,
      excelFields
    }
  },
}
</script>

<style>

</style>
